import type DataGridControl from './DataGridControl.ts';
import { watch } from 'vue';

export default function useAllowEditWatcher(options: {
    gridControl: Ref<DataGridControl>,
}) {
    watch(() => options.gridControl.value?.state.allowUpdate, (allow: boolean) => {
        if (!allow && options.gridControl.value!.navigation.editMode) {
            options.gridControl.value!.navigation.exitEditMode();
        }
    });
}

type Ref<T> = { value?: T };